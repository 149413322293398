"use client";

import { createCheckout } from "@/app/data/shopify/create-checkout.action";
import TextInputField from "@/components/ui/Form/TextInput";
import { CGCPrismicContext } from "@/prismicio";
import {
  styleGuideTypography,
  styleGuideTypographySmall,
} from "@/utils/style-guide-typography";
import { ShopifyCheckoutData } from "@cgc/shopify/shopify-storefront";
import { Button } from "@cgc/ui/button";
import { Heading } from "@cgc/ui/typography";
import { Content } from "@prismicio/client";
import { PrismicNextImage } from "@prismicio/next";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { Field, Form, Formik } from "formik";
import { useSearchParams } from "next/navigation";
import * as Yup from "yup";

/**
 * Props for `SubscriptionCheckout`.
 */
export type SubscriptionCheckoutProps = SliceComponentProps<
  Content.SubscriptionCheckoutSlice,
  CGCPrismicContext
>;

/**
 * Component for "SubscriptionCheckout" Slices.
 */
const SubscriptionCheckout = ({
  slice,
  context,
}: SubscriptionCheckoutProps): JSX.Element => {
  const validationSchema = Yup.object<ShopifyCheckoutData>().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    acceptsMarketing: Yup.boolean(),
    productId: Yup.number().required("Product ID is required"),
    variantId: Yup.number().required("Variant ID is required"),
  });

  const searchParams = useSearchParams();

  // Extract language and country from lang. e.g. en-de
  const [languageCode, countryCode] = context.lang.split("-");

  const discountCode = searchParams.get("discount_code") ?? "";

  async function onSubmit(values, { setSubmitting }) {
    const checkoutUrl = await createCheckout(values, countryCode, languageCode);

    console.log({ checkoutUrl });

    // Redirect to checkoutUrl
    window.location.href = checkoutUrl;

    // Handle form submission
    setSubmitting(false);
  }

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="flex flex-col lg:flex-row">
        <div className="text-cgc-blue order-last mx-auto mt-16 flex flex-col justify-center px-3 text-center lg:order-first lg:w-2/3">
          <Heading.h2 className="mb-4">{slice.primary.title}</Heading.h2>

          <Formik<ShopifyCheckoutData>
            initialValues={{
              email: "",
              acceptsMarketing: false,
              productId: slice.primary.offer_product_id,
              variantId: slice.primary.offer_product_variant_id,
              sellingPlanId: slice.primary.offer_product_selling_plan_id,
              discountCode: discountCode,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="mx-auto max-w-96 space-y-4">
                <TextInputField
                  name="email"
                  type="email"
                  placeholder={slice.primary.email_input_placeholder}
                  className="border-cgc-grey border"
                />

                <label className="relative mx-auto mt-6 inline-flex gap-x-2 text-left text-sm">
                  <Field type="checkbox" name="emailSubscribe" />
                  <PrismicRichText
                    field={slice.primary.opt_in_text}
                    components={styleGuideTypographySmall}
                  />
                </label>

                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  <span>{slice.primary.button_text}</span>
                </Button>
              </Form>
            )}
          </Formik>
        </div>

        <div className="bg-cgc-yellow relative flex items-center justify-center p-8 lg:h-[calc(100vh-136px)] lg:w-1/3 lg:max-w-sm">
          <div className="flex w-full flex-col">
            <Heading.h3 className="text-cgc-blue text-center">
              {slice.primary.offer_product_heading}
            </Heading.h3>
            <div className="border-cgc-blue relative flex w-full flex-col gap-4 rounded-md border border-opacity-10 bg-white p-4">
              <div className="flex w-full gap-4 lg:flex-col">
                <PrismicNextImage
                  field={slice.primary.offer_product_image}
                  sizes="100vw"
                  className="aspect-square h-full w-1/3 rounded-md object-cover lg:aspect-auto lg:h-48 lg:w-full"
                  fallbackAlt={""}
                />

                <div className="w-2/3 lg:w-full">
                  <div className="flex flex-col justify-between lg:flex-row">
                    <Heading.h4>{slice.primary.offer_product_title}</Heading.h4>

                    <Heading.h4 className="text-cgc-green">
                      <span className="decoration-cgc-blue line-through">
                        {slice.primary.offer_product_strikethrough_price}
                      </span>
                      &nbsp;
                      {slice.primary.offer_product_price}
                    </Heading.h4>
                  </div>
                </div>
              </div>

              <PrismicRichText
                field={slice.primary.offer_product_description}
                components={styleGuideTypography}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionCheckout;

import { Berry } from "@cgc/ui/icons";
import { Heading, Paragraph } from "@cgc/ui/typography";

export const styleGuideTypography = {
  heading1: ({ children, key }) => (
    <Heading key={key} display>
      {children}
    </Heading>
  ),
  heading2: ({ children, key }) => (
    <Heading.h2 key={key} display>
      {children}
    </Heading.h2>
  ),
  heading3: ({ children, key }) => (
    <Heading.h3 key={key} display>
      {children}
    </Heading.h3>
  ),
  heading4: ({ children, key }) => (
    <Heading.h4 key={key}>{children}</Heading.h4>
  ),
  heading5: ({ children, key }) => (
    <Paragraph size="sub2" key={key}>
      {children}
    </Paragraph>
  ),
  heading6: ({ children, key }) => (
    <Paragraph size="sub3" key={key}>
      {children}
    </Paragraph>
  ),
  paragraph: ({ children, key }) => <Paragraph key={key}>{children}</Paragraph>,
};

export const pseudoHeadingGuideTypography = {
  heading1: ({ children, key }) => (
    <Heading key={key} display as="span">
      {children}
    </Heading>
  ),
  heading2: ({ children, key }) => (
    <Heading.h2 key={key} display as="span">
      {children}
    </Heading.h2>
  ),
  heading3: ({ children, key }) => (
    <Heading.h3 key={key} display as="span">
      {children}
    </Heading.h3>
  ),
  heading4: ({ children, key }) => (
    <Heading.h4 key={key} as="span">
      {children}
    </Heading.h4>
  ),
  heading5: ({ children, key }) => (
    <Paragraph size="sub2" key={key}>
      {children}
    </Paragraph>
  ),
  heading6: ({ children, key }) => (
    <Paragraph size="sub3" key={key}>
      {children}
    </Paragraph>
  ),
  paragraph: ({ children, key }) => <Paragraph key={key}>{children}</Paragraph>,
};

export const styleGuideTypographySmallMobile = {
  heading1: ({ children, key }) => (
    <Heading key={key} display className="text-3xl md:text-9xl">
      {children}
    </Heading>
  ),
  heading2: ({ children, key }) => (
    <Heading.h2 key={key} display className="text-2xl md:text-7xl">
      {children}
    </Heading.h2>
  ),
  heading3: ({ children, key }) => (
    <Heading.h3 key={key} display className="text-xl md:text-4xl">
      {children}
    </Heading.h3>
  ),
  heading4: ({ children, key }) => (
    <Heading.h4 key={key} display className="text-lg md:text-2xl">
      {children}
    </Heading.h4>
  ),
  heading5: ({ children, key }) => (
    <Paragraph size="sub2" key={key}>
      {children}
    </Paragraph>
  ),
  heading6: ({ children, key }) => (
    <Paragraph size="sub3" key={key}>
      {children}
    </Paragraph>
  ),
  paragraph: ({ children, key }) => (
    <Paragraph key={key} className="text-base lg:text-lg">
      {children}
    </Paragraph>
  ),
};

export const styleGuideTypographySmall = {
  heading1: ({ children, key }) => (
    <Heading key={key} display className="text-3xl md:text-6xl">
      {children}
    </Heading>
  ),
  heading2: ({ children, key }) => (
    <Heading.h2 key={key} display className="text-2xl md:text-5xl">
      {children}
    </Heading.h2>
  ),
  heading3: ({ children, key }) => (
    <Heading.h3 key={key} display className="text-xl md:text-3xl">
      {children}
    </Heading.h3>
  ),
  heading4: ({ children, key }) => (
    <Heading.h4 key={key} display className="text-lg md:text-xl">
      {children}
    </Heading.h4>
  ),
  heading5: ({ children, key }) => (
    <Paragraph size="p1" key={key}>
      {children}
    </Paragraph>
  ),
  heading6: ({ children, key }) => (
    <Paragraph size="p2" key={key}>
      {children}
    </Paragraph>
  ),
  paragraph: ({ children, key }) => (
    <Paragraph size="p2" key={key}>
      {children}
    </Paragraph>
  ),
  listItem: ({ children, key }) => (
    <li className="flex items-start gap-2 text-left">
      <Berry className="mt-1 size-3 max-h-3 min-h-3 min-w-3 max-w-3 fill-current" />

      <Paragraph size="p2" key={key}>
        {children}
      </Paragraph>
    </li>
  ),
  oListItem: ({ children, key }) => (
    <li className="flex items-start gap-2 text-left">
      <Berry className="mt-1 size-3 max-h-3 min-h-3 min-w-3 max-w-3 fill-current" />

      <Paragraph size="p2" key={key}>
        {children}
      </Paragraph>
    </li>
  ),
};

export const styleGuideTypographyWithHighlight = {
  ...styleGuideTypography,
  em: ({ children }) => <span className="text-cgc-yellow">{children}</span>,
};

export const styleGuideTypographyWithHighlightAndUnderline = {
  ...styleGuideTypography,
  em: ({ children }) => (
    <span className="text-cgc-blue decoration-cgc-yellow underline decoration-4 underline-offset-0">
      {children}
    </span>
  ),
};

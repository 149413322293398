"use client";

import { Label } from "@cgc/ui/Form";
import { Paragraph, ParagraphProps } from "@cgc/ui/typography";
import { clsx } from "@cgc/ui/utils/clsx";
import { useField } from "formik";
import React from "react";

const ResponsiveParagraph = (props: ParagraphProps) => (
  <>
    <Paragraph {...props} size="p3" mobileOnly />
    <Paragraph {...props} size="p2" desktopOnly />
  </>
);

type Props = {
  name: string;
  label?: string;
  labelClassName?: string;
  type?: "text" | "email" | "password" | "checkbox";
  placeholder?: string;
  className?: string;
  onDark?: boolean;
};

// FIXME: sync this field for full width newsletter subscription slice
// DEPRECATED
const TextInputField: React.FC<Props> = ({
  name,
  label,
  labelClassName,
  type = "text",
  placeholder,
  className,
  onDark,
}) => {
  const [field, meta] = useField(name);
  const fieldId = `id_${field.name}`;
  const hasError = meta.touched && meta.error;
  const color = hasError ? "failure" : undefined;

  return (
    <div className="mb-2 w-full">
      {label && (
        <div className="block">
          <Label
            htmlFor={fieldId}
            color={color}
            className={clsx("text-cgc-blue text-lg", labelClassName)}
          >
            {label}
          </Label>
        </div>
      )}

      <input
        className={clsx(
          "focus:shadow-0 bg-cgc-white text-cgc-black border-cgc-blue h-9 w-full rounded-lg border p-3 text-base font-normal lg:text-lg",
          hasError && "!border-cgc-error",
          className,
        )}
        id={fieldId}
        type={type}
        placeholder={placeholder}
        {...field}
      />
      {hasError && (
        <ResponsiveParagraph
          className={clsx(
            "mt-1 whitespace-nowrap",
            onDark ? "text-cgc-error-light" : "text-cgc-error",
          )}
        >
          {meta.error}
        </ResponsiveParagraph>
      )}
    </div>
  );
};

export default TextInputField;
